import nextI18next from 'next-i18next.config'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import CountryLanguageSelector from '@cms/components/CountryLanguageSelector'
import type { CMSPageProps } from '@cms/utils/types'
import Page from '@components/Layout/Page'
import i18nConfig from '@config/i18n'
import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web/lib/sharedContentfulProps'
import { useTheme } from '@mui/material/styles'

const GatewayPage: React.FC<CMSPageProps> = ({
  footerEntries,
  cmsPageData,
  locale,
  appsConfig,
}) => {
  const cmsPageFields = cmsPageData?.fields
  const theme = useTheme()

  return (
    <Page
      footerEntries={footerEntries}
      cmsPageFields={cmsPageFields}
      isMiniFooter
      locale={locale}
      sx={{
        '& .page-wrapper': {
          backgroundColor: theme.palette.background.background,
        },
      }}
      appsConfig={appsConfig}
      isMiniHeader
    >
      <CountryLanguageSelector
        dataCy="gateway-page"
        regions={{
          europe: i18nConfig?.countries?.europe,
          america: i18nConfig?.countries?.america,
          asiaPacific: i18nConfig?.countries?.asia,
          africaMiddleEastIndia: i18nConfig?.countries?.africa,
          global: i18nConfig?.countries?.global,
        }}
      />
    </Page>
  )
}

export const getServerSideProps = async ({ locale, preview }) => {
  const { headerEntries, footerEntries } = await sharedContentfulProps({
    locale,
    preview,
  })

  return {
    props: {
      ...((await serverSideTranslations(locale, ['cms'], nextI18next)) || {}),
      ...(await getAppConfigProps()),
      headerEntries,
      footerEntries,
      locale,
    },
  }
}

export default GatewayPage
