
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/choose-your-region",
      function () {
        return require("private-next-pages/choose-your-region.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/choose-your-region"])
      });
    }
  